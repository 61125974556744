// History styles
$arrow-spacing: 60px;

.history-slider-container {
    position: relative;
    padding: 0 $arrow-spacing;
    &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: calc(100% - (#{$arrow-spacing} * 2));
        background-color: $dark;
        top: var(--height);
        margin-top: 3rem;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .tns-controls [data-controls] {
        top: var(--height);
        margin-top: 1.5rem;
    }
}
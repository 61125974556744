// Custom Navbar styles
.navbar {
    transition: transform .5s cubic-bezier(0.86, 0, 0.07, 1);

    &.hidden {
        transform: translateY(-100%);
    }
}

.navbar-nav:not(.customer-center) {    
    
    &:hover .nav-link {
        opacity: .5;
    }

    .nav-link {
        transition: opacity .15s ease-in-out;

        @include media-breakpoint-up(lg) {
            margin-left: $navbar-nav-link-padding-x;
            margin-right: $navbar-nav-link-padding-x;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .nav-link:hover, .nav-link.show {
        opacity: 1;
    }
}

.desktop-search {
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $white;
        opacity: .75;
        font-size: .875rem;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $white;
        opacity: .75;
        font-size: .875rem;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $white;
        opacity: .75;
        font-size: .875rem;
    }
    :-moz-placeholder { /* Firefox 18- */
        color: $white;
        opacity: .75;
        font-size: .875rem;
    }
}
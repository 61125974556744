/*!
 GT America Compressed Black Font
 */

@font-face {
  font-family: 'GT America Compressed';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('../webfonts/GT-America-Compressed-Black.eot');
  src: url('../webfonts/GT-America-Compressed-Black.eot?#iefix') format('embedded-opentype'),
  url('../webfonts/GT-America-Compressed-Black.woff2') format('woff2'),
  url('../webfonts/GT-America-Compressed-Black.woff') format('woff'),
  url('../webfonts/GT-America-Compressed-Black.ttf') format('truetype');
}
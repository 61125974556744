// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/functions";

// 2. Include any default variable overrides here

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true !default;
$enable-rounded:              false;
$enable-shadows:              false;
$enable-gradients:            false !default;
$enable-transitions:          true !default;
$enable-reduced-motion:       true !default;
$enable-smooth-scroll:        true !default;
$enable-grid-classes:         true !default;
$enable-cssgrid:              true;
$enable-button-pointers:      true !default;
$enable-rfs:                  true !default;
$enable-validation-icons:     true !default;
$enable-negative-margins:     true;
$enable-deprecation-messages: true !default;
$enable-important-utilities:  true !default;

// Object Fit
$object-fits: (
    "fill":       fill,
    "contain":    contain,
    "cover":      cover,
    "scale-down": scale-down,
    "none":      none
);

// Object Positions
$object-positions: (
    "top":      top,
    "bottom":   bottom,
    "left":     left,
    "right":    right,
    "center":   center,
    "unset":    unset,
    75:         75%  
);

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// blues
$blue-100: #00378C;
$blue-200: #0b2653;
$blue-300: #081e46;
$blue-400: #51668a;
$blue-500: #EFF2F8;

// Westex Custom colors
$primary:   $blue-100;
$secondary: $blue-200;
$accent:    #E12B27;
$dark:      $blue-300;
$light:     #f7f7f7;
$lt-gray:   #f3f3f3;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "accent":     $accent,
  "dark":       $dark,
  "white":      $white,
  "light":      $light,
  "lt-gray":    $lt-gray,
  "body":       $blue-400,
  "lt-blue":    $blue-500,
);

$theme-colors: map-remove($theme-colors, "info");

$gradient: linear-gradient(180deg, rgba($white, 1) 50%, rgba($white, 0));

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xxs: 0,
  xs: 371px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $blue-400;
$body-text-align:           null !default;

// Links
//
// Style anchor elements.

$link-color:                              $primary !default;
$link-decoration:                         underline !default;
$link-shade-percentage:                   20% !default;
$link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration:                   null !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
//
$ff-america:        "GT America", serif;
$ff-america-cond:   "GT America Condensed", serif;
$ff-america-comp:   "GT America Compressed", serif;

$font-families: (
    "america":      $ff-america,
    "america-cond": $ff-america-cond,
    "america-comp": $ff-america-comp
);

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      "GT America", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// stylelint-enable value-keyword-case
$font-family-base:            $ff-america;

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;
$font-weight-bolder:          bolder;

$font-weight-base:  $font-weight-normal;

$display-font-weight: 700;

// Font sizes
$font-size: 1rem;
$font-size-xxs:    $font-size * .625;
$font-size-xs:     $font-size * .75;
$font-size-sm:     $font-size * .875;
$font-size-md:     $font-size * 1.125;
$font-size-lg:     $font-size * 1.25;
$font-size-xl:     $font-size * 1.375;
$font-size-xxl:    $font-size * 1.5;

$font-sizes: (
    xxs:    $font-size-xxs,
    xs:     $font-size-xs,
    sm:     $font-size-sm,
    base:   $font-size,
    md:     $font-size-md,
    lg:     $font-size-lg,
    xl:     $font-size-xl,
    xxl:    $font-size-xxl
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 7,
  10: $spacer * 8
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "21x9": calc(9 / 21 * 100%),
  "3x4": calc(4 / 3 * 100%),
);


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         .5rem !default;

// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           2.5rem;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
//$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        0;
$navbar-toggler-transition:         0;
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  transparent;
// scss-docs-end navbar-theme-variables

// Dropdowns
//
// Dropdown menu container and contents.

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;

// scss-docs-start dropdown-variables
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-divider-bg:               $dropdown-border-color !default;
$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color:               $white;
$dropdown-dark-bg:                  $secondary;
$dropdown-dark-border-color:        $dropdown-border-color !default;
$dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
$dropdown-dark-box-shadow:          null !default;
$dropdown-dark-link-color:          $dropdown-dark-color !default;
$dropdown-dark-link-hover-color:    $white !default;
$dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
$dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg:      $dark;
$dropdown-dark-link-disabled-color: $gray-500 !default;
$dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Close Button Variables
$btn-close-width:            1em;
$btn-close-height:           $btn-close-width;
$btn-close-padding-x:        .25em;
$btn-close-padding-y:        $btn-close-padding-x;
$btn-close-color:            $white;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
//$btn-close-focus-shadow:     $input-btn-focus-box-shadow;
$btn-close-opacity:          .5;
$btn-close-hover-opacity:    .75;
$btn-close-focus-opacity:    1;
$btn-close-disabled-opacity: .25;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%);

// Offcanvas

// scss-docs-start offcanvas-variables
//$offcanvas-padding-y:               $modal-inner-padding !default;
//$offcanvas-padding-x:               $modal-inner-padding !default;
$offcanvas-horizontal-width:        400px !default;
$offcanvas-vertical-height:         30vh !default;
$offcanvas-transition-duration:     .3s !default;
//$offcanvas-border-color:            $modal-content-border-color !default;
//$offcanvas-border-width:            $modal-content-border-width !default;
//$offcanvas-title-line-height:       $modal-title-line-height !default;
//$offcanvas-bg-color:                $modal-content-bg !default;
//$offcanvas-color:                   $modal-content-color !default;
$offcanvas-box-shadow:              0;
//$offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
//$offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables

// Forms
$input-box-shadow:      none;

// Breadcrumbs

// scss-docs-start breadcrumb-variables
$breadcrumb-font-size:              .875rem;
$breadcrumb-padding-y:              0 !default;
$breadcrumb-padding-x:              0 !default;
$breadcrumb-item-padding-x:         .5rem !default;
$breadcrumb-margin-bottom:          0;
$breadcrumb-bg:                     null !default;
$breadcrumb-divider-color:          $body-color;
$breadcrumb-active-color:           $body-color;
$breadcrumb-divider:                quote(">");
$breadcrumb-divider-flipped:        $breadcrumb-divider !default;
$breadcrumb-border-radius:          null !default;

// Shadows
$box-shadow:                  0px 5px 83px rgba($black, .075);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);

// scss-docs-end breadcrumb-variables

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/root";

// 4. Include any optional Bootstrap CSS as needed
@import "bootstrap/utilities";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/transitions";
@import "bootstrap/forms";
@import "bootstrap/breadcrumb";
@import "bootstrap/navbar";
@import "bootstrap/nav";
@import "bootstrap/dropdown";
@import "bootstrap/offcanvas";
@import "bootstrap/close";
@import "bootstrap/tooltip";
@import "bootstrap/list-group";
@import "bootstrap/tables";
@import "bootstrap/badge";
@import "bootstrap/pagination";
@import "bootstrap/modal";
@import "bootstrap/close";
@import "bootstrap/accordion";
@import "bootstrap/helpers";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utililies.scss`
@import "bootstrap/utilities/api";
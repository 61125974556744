// Hero styles
$navbar: var(--navbar);

// Home Scroller Images
// $name, $xl-file, $lg-file, $md-file, $sm-file
$hero-img: (
    "about"     75% bottom 75% bottom 75% bottom 75% bottom,
    "blog"      50% 50% 50% 50% 50% 50% 50% 50%,
    "contact"   50% center 50% center 40% center 35% center,
    "fabrics"   50% 50% 50% 50% 50% 50% 50% 50%,
    "hazards"   50% center 50% center 50% center 50% center,
    "history"   50% center 50% center 50% center 50% center,
    "home"      50% 0 50% 0 50% 0 40% 0,
    "industry"  50% center 50% center 50% center 50% center,
    "samples"   75% 50% 75% 50% 75% 50% 75% 50%,
    "resources" 50% center 50% center 50% 50% 25% 50%,
    "science"   50% 50% 50% 50% 50% 50% 50% 50%,
    "app"       50% center 50% center 50% center 50% center,
    "where-buy" 50% 50% 50% 50% 50% 50% 75% 50%
    "legal"     75% bottom 75% bottom 75% bottom 75% bottom
);

.hero-img-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    display: flex;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background: $dark;
        background: linear-gradient(320deg, rgba($dark, 0) 0, rgba($dark, 1) 100%);
    }

    &.primary {
        @media (orientation: landscape) {
            min-height: calc(50vh - #{$navbar});
        }
    }

    &.home {
        @media (orientation: landscape) {
            min-height: calc(100vh - #{$navbar});
        }        
    }

    .hero-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;

        @each $name, $xl-x, $xl-y, $lg-x, $lg-y, $md-x, $md-y, $sm-x, $sm-y in $hero-img {
            &.#{$name} {

                img {                
                    object-position: $xl-x $xl-y;
        
                    @media (max-width: 1801px) {
                        object-position: $lg-x $lg-y;
                    }
        
                    @media (max-width: 1201px) {
                        object-position: $md-x $md-y;
                    }
        
                    @media (max-width: 601px) {
                        object-position: $sm-x $sm-y;
                    }
                }
            }
        }
    }
}
// Styles just for touch devices

@media (pointer: coarse) {
  .touch-link {
      font-size: 1.125rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
  }
}
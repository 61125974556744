// Tiny Slider styles

.tns-nav {
    padding: 1rem 0;
    text-align: center;

    button {
        height: 5px;
        line-height: 5px;
        width: 30px;
        background-color: rgba($primary, .5);
        padding: 0px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
        border-radius: 2px;
        margin: 0 0.375rem;

        &.tns-nav-active {
            background-color: rgba($primary, 1);
        }
    }
}

$carousel-control-size: 2.75rem;

.tns-controls {

    .tns-controls:focus { outline: none; }

    [data-controls] {
        position: absolute;
        top: 50%;
        width: $carousel-control-size;
        height: $carousel-control-size;
        margin-top: -$carousel-control-size;
        transition: all .3s ease-in-out;
        border: none;
        border-radius: 50%;
        background-color: $primary;
        font-size: $carousel-control-size * .294;
        line-height: $carousel-control-size - .125rem;
        text-align: center;
        z-index: 10;
        color: $white;
        &[disabled] { opacity: 0 !important; }
    }

    [data-controls='prev'] { left: 0; }
    [data-controls='next'] { right: 0; }
}

$arrow-spacing: 60px;

.slider-container {
    position: relative;
    padding: 0 $arrow-spacing;
}
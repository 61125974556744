/*!
 GT America Regular Font
 */

@font-face {
  font-family: 'GT America';
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url('../webfonts/GT-America-Standard-Regular-Italic.eot');
  src: url('../webfonts/GT-America-Standard-Regular-Italic.eot?#iefix') format('embedded-opentype'),
  url('../webfonts/GT-America-Standard-Regular-Italic.woff2') format('woff2'),
  url('../webfonts/GT-America-Standard-Regular-Italic.woff') format('woff'),
  url('../webfonts/GT-America-Standard-Regular-Italic.ttf') format('truetype');
}
// Background Image styles

.bg-img-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
    display: flex;

    .bg-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;        
    }
}
/*!
 GT America Compressed Black Font
 */

@font-face {
  font-family: 'GT America Compressed';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url('../webfonts/GT-America-Compressed-Bold.eot');
  src: url('../webfonts/GT-America-Compressed-Bold.eot?#iefix') format('embedded-opentype'),
  url('../webfonts/GT-America-Compressed-Bold.woff2') format('woff2'),
  url('../webfonts/GT-America-Compressed-Bold.woff') format('woff'),
  url('../webfonts/GT-America-Compressed-Bold.ttf') format('truetype');
}
.nav-link {

    &.active {
        text-decoration: underline 2px;
        text-underline-offset: .5em;
    }
}

.footer-nav {
    a {
        text-decoration:none;
    
        &.active {
            text-decoration: underline 2px;
            text-underline-offset: 0.2em;
        }

        &:hover, &:focus {
            text-decoration: underline 2px;
            text-underline-offset: 0.2em;
        }
    }
}

.footer-social a {
    margin: 5px 3px;
    display: inline-block;
    width: 44px;
    height: 44px;
    font-size: 18px;
    line-height: 44px;
    border-radius: 42px;
    text-align: center;
    color: #fff;
    border: 2px solid rgba(81,102,138,.5);
    position: relative;
    transition: all .15s ease-in-out;

    &:hover {
        border: 2px solid $white;
    }
}
// SCSS SYNTAX
@use "sass:color";

// Font
@import "gt-america/regular.scss";
@import "gt-america/bold.scss";
@import "gt-america/italic.scss";
@import "gt-america/comp-black.scss";
@import "gt-america/comp-bold.scss";
@import "gt-america/cond-bold.scss";

// Project styles
@import "variables";
@import "utilities";
@import "navbar";
@import "hero";
@import "tabs";
@import "navs";
@import "resources";
@import "tinyslider";
@import "hover";
@import "history";
@import "touch";
@import "bg-img";

@import "fontawesome/fontawesome.scss";
@import "fontawesome/solid.scss";
@import "fontawesome/regular.scss";
@import "fontawesome/brands.scss";

.body {
    overflow-y: hidden;
    margin: 0;
    padding: 0;
}

section {
    &:not(.container) {
        width: 100%;
        overflow-y: hidden;
    }    
}

.btn-white {
    color: $primary !important;
}

sup {
    font-size: .55em !important;
}

.accordion-button {
    display: block !important;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    text-wrap: balance;
}
div,p,li {
    text-wrap: pretty;
}
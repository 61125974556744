// Project utilities
//

// Text
//
// Font Families
@each $key, $val in $font-families {
    .ff-#{$key}  {
        font-family: $val;
    }
}
// Font Sizes
.fs {
    @each $size, $value in $font-sizes {
        &-#{$size} {
            font-size: #{$value};
        }
    }
}

@each $key, $val in $object-fits {
    .object-fit-#{$key} {
        object-fit: $val;
    }
}

@each $key, $val in $object-positions {
    .object-position-#{$key} {
        object-position: $val;
    }
}

.btn-collapse {
    .btn-collapse-text {
        &::before {
            content: "Read More";
        }
    }   
     
    &:not(.collapsed) {
        .btn-collapse-text {
            &::before {
                content: "Read Less";
            }
        }   
    }
}

.fw-medium {
    font-weight: 500;
}

.fw-black {
    font-weight: 900;
}

.btn-video {
    text-align: center;
    height: 60px;
    width: 60px;
    background-color: #f9f9f9;
    background-image: none;
    z-index: 1;
    font-size: 17px;
    color: $primary;
    border: none;
    border-radius: 100%;
    display: inline-block;
    position: relative;
    
    i {
      position: absolute;
      top: 50%;
      left: 52%;
      transform: translate(-50%, -52%);
      font-size: 1rem;
    }
    
    .line-video-animation {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-radius: 100%;
        animation: ripples 3.9s linear 0s infinite;
        animation-play-state: running;
        opacity: 0;
        
        &.line-video-2 {
          animation-delay: 1.3s;
        }
        
        &.line-video-3 {
            animation-delay: 2.6s;
        }
    }

    &:hover {
        color: $primary;
        
        .line-video-animation {
            animation-play-state: paused;
        }
    }

    &-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

@keyframes ripples {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  20% {
    transform: scale(1.24);
    opacity: 1;
  }
  100% {
    transform: scale(2.1);
    opacity: 0;
  }
}

.d-col-2 {
    column-count: 2;
    column-width: 165px;
    column-gap: 1.25rem;
    widows: 1;
    orphans: 1;
}
// Resources styles

.resource {
    position: relative;
    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 80%;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 1;
        background: linear-gradient(to bottom,rgba(4,17,39,0) 0%,rgba(4,17,39,0) 25%,#041127 92%);
    }
}

.form-dark {
    .form-control {
        background-color: rgba($white, 0.25);
        color: $white;
        border-color: rgba($white, 0.25);
    }

    label {
        color: rgba($white, 0.85);
    }
}